import React, { useRef } from 'react';
import './Landing.css';
import AutoPlaySilentVideo from './AutoPlaySilentVideo.js';
import AdComponent from './AdComponent.js';

function Landing() {

    const videoSectionRef = useRef(null);

    // Function to scroll to the video section
    const scrollToVideoSection = () => {
        window.scrollTo({
            top: videoSectionRef.current.offsetTop,
            behavior: 'smooth' // Optional: defines the transition animation
        });
    };

    return (
        <body>
            <section class='landing-div'>
                <h2 class='landing-word'>Caption Crafter</h2>
                <h1 class='landing-info'>Elevate your social media game with personalized, AI-generated captions for every picture you upload.</h1>
                <button class='get-started-button' onClick={() => window.location.href = '/login'}>Get started for free</button>
                <div className="how-it-works-container">
                    <button onClick={scrollToVideoSection} className="how-it-works-button">How it works?</button>
                </div>
            </section>
            <section ref={videoSectionRef} className='video-section'>
                <div className='video-container'>
                    <h2 className='video-header'>Upload your photo and receive captions featuring both trending and specialized hashtags.</h2>
                    <AutoPlaySilentVideo className='video-item' video="../sample.mp4" />
                </div>
                <div className='video-container'>
                    <h2 className='video-header'>Retrieve and share your caption history at any time.</h2>
                    <AutoPlaySilentVideo className='video-item' video="../history.mp4" />
                </div>
            </section>
            <section class='how-it-works'>
                <h1 class='how-it-works-title'>How it works</h1>
                <div class='how-it-works-div'>
                    <div class='how-it-works-info'>
                        <h2 class='how-it-works-header'>1. Sign Up for Free Credits</h2>
                        <p class='how-it-works-text'>Simply register with your email, and we’ll kickstart your journey with 3 complimentary credits</p>
                    </div>
                    <div class='how-it-works-info'>
                        <h2 class='how-it-works-header'>2. Upload and Engage</h2>
                        <p class='how-it-works-text'>Share your photo and witness the art of AI as it conjures up personalized captions complete with trending and niche hashtags, tailored to boost your post's reach and engagement</p>
                    </div>
                    <div class='how-it-works-info'>
                        <h2 class='how-it-works-header'>3. Privacy is Paramount</h2>
                        <p class='how-it-works-text'>Your photos remain yours alone. We respect your privacy by not storing any images — only the captions we craft for you are saved</p>
                    </div>
                    <div class='how-it-works-info'>
                        <h2 class='how-it-works-header'>Need More Magic?</h2>
                        <p class='how-it-works-text'>Keep an eye out! Our convenient payment options for additional credits are coming your way soon</p>
                    </div>
                </div>
            </section>
        </body >
    );
}

export default Landing;