import React from 'react';

function PaymentCancel() {

    return (
        <div>
            <h2>Payment Cancelled</h2>
        </div>
    );
}

export default PaymentCancel;