import React, { useEffect, useState, useRef } from 'react';
import './Profile.css';
import { getAuth } from 'firebase/auth';
import { getDatabase, ref, onValue } from 'firebase/database';
import gsap from 'gsap';

export default function Profile() {
    const [userInfo, setUserInfo] = useState(null);
    const [totalCredits, setTotalCredits] = useState(null);
    const [userEmail, setUserEmail] = useState(null);

    const [isActive, setIsActive] = useState(false);
    const linksRef = useRef(null);
    const backgroundRef = useRef(null);
    const burgerRef = useRef(null);

    useEffect(() => {

        const auth = getAuth();
        //const currentuser = auth.currentUser;

        const handleAuthStateChanged = (user) => {
            if (user) {
                const db = getDatabase();
                const userRef = ref(db, `users/${user.uid}/creditsProfile`);

                //console.log("User Ref: " + userRef);
                //console.log("User ID " + user.uid);
                //console.log("User: " + user.email);
                //console.log("Credits: " + user.totalCredits);

                setUserEmail(user.email);
                //setTotalCredits(user.totalCredits);

                const unsubscribe = onValue(userRef, (snapshot) => {
                    if (snapshot.exists()) {
                        const userData = snapshot.val();
                        //console.log("snapshot " + snapshot.val())
                        //console.log("Total Credits: " + userData.totalCredits);
                        setTotalCredits(userData.totalCredits);
                    } else {
                        //console.log("No data available");
                        setTotalCredits(null);
                    }
                }, (error) => {
                    console.error(error);
                });

                // Cleanup function to unsubscribe
                return unsubscribe;
            } else {
                setTotalCredits(null);
            }
        };

        const unsubscribeAuth = auth.onAuthStateChanged(handleAuthStateChanged);

        // Cleanup function to unsubscribe from auth state changes
        return () => unsubscribeAuth();
    }, []);

    const handleSignOut = (user) => {
        const auth = getAuth();
        auth.signOut()
            .then(() => {
                window.location.href = '/login'
            })
            .catch(error => alert(error.message))
    };

    useEffect(() => {
        if (isActive) {

            gsap.to(backgroundRef.current, {
                autoAlpha: 1,
                zIndex: 10, // Brings the background to the front
                duration: 0.3
            });
            gsap.to(linksRef.current, {
                autoAlpha: 1,
                duration: 0.3,
                delay: 0.3 // Delay to wait for background to appear
            });
            gsap.to(burgerRef.current.querySelectorAll('.line'), { stroke: '#fff', duration: 0.5, zIndex: 11, autoAlpha: 1 }); //white

        } else {
            // Hide links first
            gsap.to(linksRef.current, {
                autoAlpha: 0,
                duration: 0.3
            });

            gsap.to(backgroundRef.current, {
                autoAlpha: 0,
                zIndex: -1, // Moves the background to the back
                duration: 0.3
            });
            gsap.to(burgerRef.current.querySelectorAll('.line'), { stroke: '#000', duration: 0.5, zIndex: 11 });
        }
    }, [isActive]); // Dependency on isActive

    const initiateCheckout = async () => {
        /*try {
            window.location.href = 'https://buy.stripe.com/test_4gw6qTdzY82CfWobIJ';
        }
        catch
        {
            console.log("error");
        }*/
        alert("Coming Soon!");
    };

    const toggleMenu = () => {
        setIsActive(!isActive); // Toggle the state
    };

    return (
        <>
            <nav>
                <svg
                    ref={burgerRef}
                    width="44"
                    height="18"
                    viewBox="0 0 44 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={toggleMenu}
                    style={{ cursor: 'pointer', zIndex: 11, padding: '20px' }}
                >
                    <line className="line" y1="1" x2="44" y2="1" stroke="black" strokeWidth="2" />
                    <line className="line" y1="9" x2="27" y2="9" stroke="black" strokeWidth="2" />
                    <line className="line" y1="17" x2="11" y2="17" stroke="black" strokeWidth="2" />
                </svg>
                <div ref={linksRef} className="links" style={{ opacity: 0 }}>
                    <a href="/home">Home</a>
                    <a href="/history">History</a>
                </div>
                <div
                    ref={backgroundRef}
                    className="fullscreen-background"
                ></div>
            </nav>
            <div className="container">
                <p className="userinfo">Email: {userEmail ? userEmail : 'Not Signed In'}</p>
                <p className="userinfo">Credits: {totalCredits}</p>
                <button className="purchaseButton" onClick={initiateCheckout}>
                    Buy more credits
                </button>
                <form action="/create-checkout-session" method="POST" className="checkout-button">
                    <button type="submit">
                        Checkout
                    </button>
                </form>
                <button className="signoutButton" onClick={handleSignOut}>
                    Sign Out
                </button>
            </div>
        </>
    );
}

