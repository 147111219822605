import React, { useState } from 'react';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, getAuth, GoogleAuthProvider, signInWithPopup, sendEmailVerification, sendPasswordResetEmail } from 'firebase/auth';
import { getDatabase, ref, set, get } from 'firebase/database';
import './Login.css';
import googleLogo from './assets/google-logo.svg';

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const auth = getAuth();

    const googleProvider = new GoogleAuthProvider();

    const signInWithGoogle = async () => {
        try {
            const result = await signInWithPopup(auth, googleProvider);
            const user = result.user;
            const db = getDatabase();
            const userRef = ref(db, 'users/' + user.uid);

            const snapshot = await get(userRef);
            if (!snapshot.exists()) {
                // If the user profile does not exist, create a new one
                await createProfile(user);
            }
            window.location.href = '/home'
        } catch (error) {
            console.error(error);
        }
    };

    const createProfile = async (user) => {
        const db = getDatabase();
        const path = `users/${user.uid}/creditsProfile`;
        const pathhistory = `users/${user.uid}/history`;
        try {
            await set(ref(db, path), { totalCredits: 3 });
            await set(ref(db, pathhistory), {});
        } catch (error) {
            console.error(error);
        }
    }

    const handleSingup = () => {
        createUserWithEmailAndPassword(auth, email, password)
            .then(async (userCredentials) => {
                const user = userCredentials.user;

                sendEmailVerification(user)
                    .then(() => {
                        // Email verification sent!
                    }).catch((error) => {
                        console.error(error);
                    });

                console.log('Registered with: ', user.email);
                await createProfile(user);
                alert("Email verification sent. Please verify your email before logging in.");
            })
            .catch(error => alert(error.message));
    }

    const handleSignIn = () => {
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user;
                console.log('Logged in with: ', user.email);

                if (user.emailVerified) {
                    // Navigate to Home
                    window.location.href = '/home'
                } else {
                    // Email is not verified
                    alert("Please verify your email first.");
                    auth.signOut()
                        .then(() => {
                            window.location.href = '/login'
                        })
                        .catch(error => alert(error.message))
                }

            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log('Error: ', errorCode, errorMessage);
                if (errorCode === 'auth/wrong-password') {
                    alert('Wrong password.');
                } else if (errorCode === 'auth/user-not-found') {
                    alert('User not found.');
                } else if (errorCode === 'auth/invalid-email') {
                    alert('Invalid email.');
                } else if (errorCode === 'auth/user-disabled') {
                    alert('User disabled.');
                } else if (errorCode === 'auth/too-many-requests') {
                    alert('Too many requests.');
                } else {
                    alert(errorCode);
                }
            });
    }

    const forgetPassword = () => {
        sendPasswordResetEmail(auth, email)
            .then(() => {
                alert("Password reset email sent. Please check your email.");
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log('Error: ', errorCode, errorMessage);
                if (errorCode === 'auth/invalid-email') {
                    alert('Invalid email.');
                } else if (errorCode === 'auth/user-not-found') {
                    alert('User not found.');
                } else {
                    alert(errorCode);
                }
            });
    }

    return (
        <section className="login-section">
            <div className="inputContainer">
                <p> Sign In with Email </p>
                <input
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="input"
                />

                <input
                    placeholder="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="input"
                />
            </div>
            <div className="button-container">
                <button onClick={handleSignIn} className="loginbutton">
                    Login
                </button>
                <button onClick={handleSingup} className="loginbutton buttonOutline">
                    Register
                </button>
            </div>
            <div className="forget-password-container">
                <button onClick={forgetPassword} className="forget-password-button">Forget Password?</button>
            </div>
            <div className="google-container">
                <p>OR</p>
                <button onClick={signInWithGoogle} className="google-signin-btn">
                    <img src={googleLogo} alt="Google"></img>Continue with Google</button>
            </div>
        </section >
    );
}
