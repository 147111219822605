import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDkYtY9uLg7pjmqqbqGomJiUN2J4zn9Y24",
    authDomain: "caption-crafter.firebaseapp.com",
    projectId: "caption-crafter",
    storageBucket: "caption-crafter.appspot.com",
    messagingSenderId: "799008222396",
    appId: "1:799008222396:web:d601de5071be55db5df4b9",
    measurementId: "G-9Z7ERW9S2P"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };
