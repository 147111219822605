import React, { useEffect, useState, useRef } from 'react';
import { getAuth } from 'firebase/auth';
import { getDatabase, ref, onValue } from 'firebase/database';
import moment from 'moment';
import copyToClipboard from 'clipboard-copy';
import gsap from 'gsap';
import './History.css';

function History() {

    const [historyData, setHistoryData] = useState([]);

    const [isActive, setIsActive] = useState(false);
    const linksRef = useRef(null);
    const backgroundRef = useRef(null);
    const burgerRef = useRef(null);

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                const currentUser = auth.currentUser;
                console.log("user" + currentUser);
                loadUserData(user);
            } else {
                console.log('No user logged in');
            }
        });

        return () => unsubscribe();
    }, []);

    const loadUserData = (user) => {

        const db = getDatabase();
        const historyRef = ref(db, `users/${user.uid}/history`);

        const unsubscribe = onValue(historyRef, (snapshot) => {
            if (snapshot.exists()) {
                const historyObj = snapshot.val();
                const historyArray = Object.keys(historyObj).map(key => ({
                    ...historyObj[key],
                    timestamp: key,
                })).sort((a, b) => b.timestamp - a.timestamp);

                setHistoryData(historyArray);
            } else {
                setHistoryData([]);
            }
        });

        return () => unsubscribe();

    }

    useEffect(() => {
        if (isActive) {

            gsap.to(backgroundRef.current, {
                autoAlpha: 1,
                zIndex: 10, // Brings the background to the front
                duration: 0.3
            });
            gsap.to(linksRef.current, {
                autoAlpha: 1,
                duration: 0.3,
                delay: 0.3 // Delay to wait for background to appear
            });
            gsap.to(burgerRef.current.querySelectorAll('.line'), { stroke: '#fff', duration: 0.5, zIndex: 11, autoAlpha: 1 }); //white

        } else {
            // Hide links first
            gsap.to(linksRef.current, {
                autoAlpha: 0,
                duration: 0.3
            });

            gsap.to(backgroundRef.current, {
                autoAlpha: 0,
                zIndex: -1, // Moves the background to the back
                duration: 0.3
            });
            gsap.to(burgerRef.current.querySelectorAll('.line'), { stroke: '#000', duration: 0.5, zIndex: 11 });
        }
    }, [isActive]); // Dependency on isActive

    const toggleMenu = () => {
        setIsActive(!isActive); // Toggle the state
    };

    return (
        <>
            <nav>
                <svg
                    ref={burgerRef}
                    width="44"
                    height="18"
                    viewBox="0 0 44 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={toggleMenu}
                    style={{ cursor: 'pointer', zIndex: 11, padding: '20px' }}
                >
                    <line className="line" y1="1" x2="44" y2="1" stroke="black" strokeWidth="2" />
                    <line className="line" y1="9" x2="27" y2="9" stroke="black" strokeWidth="2" />
                    <line className="line" y1="17" x2="11" y2="17" stroke="black" strokeWidth="2" />
                </svg>
                <div ref={linksRef} className="links" style={{ opacity: 0 }}>
                    <a href="/home">Home</a>
                    <a href="/profile">Profile</a>
                </div>
                <div
                    ref={backgroundRef}
                    className="fullscreen-background"
                ></div>
            </nav>
            {historyData && historyData.length > 0 ? <div className="history-container">
                {historyData.map((item, index) => (
                    <div key={index} className="history-item">
                        <div className="date-container">
                            <span className="date-text">{moment(+item.timestamp).format('YYYY-MM-DD HH:mm')}</span>
                        </div>
                        <div className="result-container">
                            <span className="result-text">{item.resulturl}</span>
                            <button className="copy-button" onClick={() => copyToClipboard(item.resulturl)}>Copy to Clipboard</button>
                        </div>
                    </div>
                ))}
            </div> : <div className="no-history-container">Your caption history will appear here</div>}
        </>
    );
}

export default History;
