import logo from './logo.svg';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './firebaseConfig';
import Login from './Login';
import Landing from './Landing';
import Home from './Home';
import History from './History';
import Profile from './Profile';
import PaymentSuccess from './PaymentSuccess';
import PaymentCancel from './PaymentCancel';
import './App.css';

function App() {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/login" element={<Login />} />
        <Route path="/home" element={<Home />} />
        <Route path="/history" element={<History />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/payment-success" element={<PaymentSuccess />} />
        <Route path="/payment-canceled" element={<PaymentCancel />} />
      </Routes>
    </Router>
  );
}

export default App;
