import React, { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { getDatabase, ref, onValue, update } from 'firebase/database';

function PaymentSuccess() {
    const [totalCredits, setTotalCredits] = useState(null);
    const [creditsAdded, setCreditsAdded] = useState(false);

    console.log("Start Payment Success");
    console.log("Start credit added state" + creditsAdded);

    useEffect(() => {
        const auth = getAuth();
        const currentUser = auth.currentUser;

        if (currentUser) {
            const db = getDatabase();
            const refPath = `users/${currentUser.uid}/creditsProfile`;
            const userRef = ref(db, refPath);

            // Attach a listener to get the current credit value
            const unsubscribe = onValue(userRef, (snapshot) => {
                if (snapshot.exists()) {
                    const userData = snapshot.val();
                    setTotalCredits(userData.totalCredits);
                    console.log("current credits are" + userData.totalCredits);
                } else {
                    console.log("No data available");
                    setTotalCredits(0); // Assume zero if not available
                }
            });

            // Cleanup function to unsubscribe from the listener
            return () => unsubscribe();
        }
    }, []);

    // Function to add credits only once
    useEffect(() => {
        if (totalCredits != null && !creditsAdded) {
            const newCredits = (totalCredits || 0) + 10;
            const auth = getAuth();
            const currentUser = auth.currentUser;

            if (currentUser) {
                const db = getDatabase();
                const userRef = ref(db, `users/${currentUser.uid}/creditsProfile`);

                update(userRef, { totalCredits: newCredits })
                    .then(() => {
                        setTotalCredits(newCredits); // Update local state
                        setCreditsAdded(true); // Prevent further additions
                        console.log(`Added 10 credits. New total: ${newCredits}`);
                    })
                    .catch((error) => {
                        console.error('Error updating credits:', error);
                    });
            }
        }
    }, [totalCredits]);

    return (
        <div>
            <h2>Your Payment is successful!</h2>
            <h2>You now have {totalCredits} credits</h2>
        </div>
    );
}

export default PaymentSuccess;