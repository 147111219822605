import React, { useState, useEffect, useRef } from 'react';
import './Home.css'; // Import your CSS file
import './Button.js';
import gsap from 'gsap';
import { getDatabase, ref, set, get } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import copyToClipboard from 'clipboard-copy';

function Home() {
    const [selectedImage, setSelectedImage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [resultUrl, setResultUrl] = useState('');
    const [imagePreviewUrl, setImagePreviewUrl] = useState(null);

    const api_key = process.env.REACT_APP_OPENAI_API_KEY;

    const [isActive, setIsActive] = useState(false);
    const linksRef = useRef(null);
    const lineRef = useRef(null);
    const backgroundRef = useRef(null);
    const burgerRef = useRef(null);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedImage(file);
            const objectURL = URL.createObjectURL(file);
            setImagePreviewUrl(objectURL);
        }
    };

    const captionAPIcall = async () => {
        if (!selectedImage) {
            alert('Please select an image first');
            return;
        }

        setIsLoading(true);
        try {
            const auth = getAuth();
            const db = getDatabase();
            const currentuser = auth.currentUser;
            const timestamp = Date.now();
            const userRef = ref(db, `users/${currentuser.uid}/creditsProfile`);
            const historyRef = ref(db, `users/${currentuser.uid}/history/${timestamp}`);
            const snapshot = await get(userRef);

            if (!snapshot.exists()) {
                alert("User credit information not found.");
                setIsLoading(false);
                return;
            }

            console.log("user" + currentuser);
            const userData = snapshot.val();
            const currentCredits = userData.totalCredits;

            console.log("Current Credits: " + currentCredits);

            if (currentCredits < 1) {
                alert("You need to buy more credits to use this feature.");
                setIsLoading(false);
                window.location.href = '/profile';
                return;
            }


            const reducedUri = await resizeImage(selectedImage);
            const base64Image = await blobToBase64(reducedUri);

            const api_url = "https://api.openai.com/v1/chat/completions";
            const response = await fetch(api_url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${api_key}`,
                },
                body: JSON.stringify({
                    model: "gpt-4-vision-preview",
                    messages: [
                        {
                            role: "user",
                            content: [
                                { type: "text", text: "Could you please generation picture a caption and hashtags that could maximize user engagement for instagram? Please limit hashtags to 10 maximum. The format should be <caption result without any hashtags> <hashtags result by order of highest recommendations>" },
                                {
                                    type: "image_url",
                                    image_url: {
                                        "url": `data:image/jpeg;base64,${base64Image}`
                                    },
                                },
                            ],
                        },
                    ],
                    max_tokens: 300
                }),
            });
            const data = await response.json();
            const resulturl = data.choices[0].message.content;

            const newCredits = currentCredits - 1;
            await set(userRef, { totalCredits: newCredits });
            await set(historyRef, { resulturl });

            setResultUrl(resulturl);

        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    async function resizeImage(file) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = URL.createObjectURL(file);

            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                let width = img.width;
                let height = img.height;

                if (width > 800) {
                    height *= 800 / width;
                    width = 800;
                }

                canvas.width = width;
                canvas.height = height;
                ctx.drawImage(img, 0, 0, width, height);

                canvas.toBlob(resolve, 'image/jpeg', 0.7);
            };

            img.onerror = reject;
        });
    }

    const blobToBase64 = (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                // Split the Data URL to get only the base64 part
                const base64String = reader.result.split(',')[1];
                resolve(base64String);
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    };

    useEffect(() => {
        if (isActive) {

            gsap.to(backgroundRef.current, {
                autoAlpha: 1,
                zIndex: 10, // Brings the background to the front
                duration: 0.3
            });
            gsap.to(linksRef.current, {
                autoAlpha: 1,
                duration: 0.3,
                delay: 0.3 // Delay to wait for background to appear
            });
            gsap.to(burgerRef.current.querySelectorAll('.line'), { stroke: '#fff', duration: 0.5, zIndex: 11, autoAlpha: 1 }); //white

        } else {
            // Hide links first
            gsap.to(linksRef.current, {
                autoAlpha: 0,
                duration: 0.3
            });

            gsap.to(backgroundRef.current, {
                autoAlpha: 0,
                zIndex: -1, // Moves the background to the back
                duration: 0.3
            });
            gsap.to(burgerRef.current.querySelectorAll('.line'), { stroke: '#000', duration: 0.5, zIndex: 11 });
        }
    }, [isActive]); // Dependency on isActive

    useEffect(() => {
        // Clean up the object URL on unmount or when a new image is selected
        return () => {
            if (imagePreviewUrl) {
                URL.revokeObjectURL(imagePreviewUrl);
            }
        };
    }, [imagePreviewUrl]);

    if (isLoading) {
        return <div className="loader-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <div className="loader"></div>
        </div>
    }

    const toggleMenu = () => {
        setIsActive(!isActive); // Toggle the state
    };


    return (
        <>
            <nav>
                <svg
                    ref={burgerRef}
                    width="44"
                    height="18"
                    viewBox="0 0 44 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={toggleMenu}
                    style={{ cursor: 'pointer', zIndex: 11, padding: '20px' }}
                >
                    <line className="line" y1="1" x2="44" y2="1" stroke="black" strokeWidth="2" />
                    <line className="line" y1="9" x2="27" y2="9" stroke="black" strokeWidth="2" />
                    <line className="line" y1="17" x2="11" y2="17" stroke="black" strokeWidth="2" />
                </svg>
                <div ref={linksRef} className="links" style={{ opacity: 0 }}>
                    <a href="/history">History</a>
                    <a href="/profile">Profile</a>
                </div>
                <div
                    ref={backgroundRef}
                    className="fullscreen-background"
                ></div>
            </nav>
            <div className="container">
                <div className="imageContainer">
                    {imagePreviewUrl ? <img className="image" src={imagePreviewUrl} alt="Selected" /> : <div className="placeholder">Photo Placeholder</div>}

                </div>
                <div className="footerContainer">
                    <div className="file-upload">
                        <input
                            type="file"
                            id="fileInput"
                            style={{
                                display: "none"
                            }}
                            onChange={handleImageChange}
                        />
                        <label htmlFor="fileInput" className="file-upload-label">
                            Select a Photo
                        </label>
                    </div>
                    <button className="buttonContainer" onClick={captionAPIcall}>Get a caption</button>
                    <p>{resultUrl}</p>
                    {resultUrl ? <button className="copy-button" onClick={() => copyToClipboard(resultUrl)}>Copy to Clipboard</button> : <div></div>}
                </div>
            </div>
        </>

    );
}

export default Home;
